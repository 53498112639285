<template>
  <div class="drainage-link">
    <div class="wx-logo">
      <img src="@/static/images/wx-logo.png" />
    </div>
    <div class="btn" @click="goWx">打开微信</div>
  </div>
</template>

<script>
import { getScheme } from "@/api/receiveFans";

export default {
  data() {
    return {
      id: "",
      link: "",
      province: "",
      city: "",
      area: "",
      customFormRecordId: ""
    };
  },
  mounted() {
    const { id, link, province, city, area, customFormRecordId, tags } = this.$route.query;
    // console.log(province);

    this.id = id;
    this.link = link;
    this.province = province;
    this.city = city;
    this.area = area;
    this.customFormRecordId = customFormRecordId;
    this.tags = tags;

    this.setTitle();
    this.goWx();
  },
  methods: {
    setTitle() {
      document.title = "添加顾问";
    },
    goWx() {
      if (!this.id) return false;
      let param = {
        id: this.id,
        link: this.link,
        scene: 1,
      };
      if (this.province) {
        param = {
          id: this.id,
          link: this.link,
          scene: 1,
          province: this.province,
        };

      }
      if (this.province && this.city) {
        param = {
          id: this.id,
          link: this.link,
          scene: 1,
          province:this.province,
          city:this.city,
          area:this.area,
        };
        
      }
      if(this.customFormRecordId) {
        param.customFormRecordId = this.customFormRecordId
      }
      if (this.tags) {
        param.tags = this.tags
      }
      // console.log(param);
      getScheme(param).then((res) => {
        window.location.href = res.data.openLink;
        // window.open(res.data.openLink);
      });
    },
  },
};
</script>

<style lang="less" scoped>

[v-clock] {
  display: none;
}
.drainage-link {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  -webkit-text-size-adjust: none !important;
}

.wx-logo {
  width: 30%;
  margin: 0 auto;
  max-width: 102px;

  img {
    width: 100%;
  }
}

.btn {
  width: 210px;
  height: 48px;
  background: #1584ff;
  border-radius: 2px;
  color: #fff;
  font-size: 16px;
  // font-weight: bold;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 17px;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(0, 0, 0, 0.25);

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 26px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }

  .text {
    margin-top: 6px;
    font-size: 13px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.25);
  margin-top: 40rpx;
}

.footer .text {
  margin-top: 18rpx;
  font-size: 13px;
}

.footer .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26rpx;
}

.footer .logo image {
  width: 46rpx;
  height: 46rpx;
  margin-right: 8rpx;
}
</style>
