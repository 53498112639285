import {request} from "@/plugins/axios"

//获取小程序码
export function getScheme(params) {
  return request({
    url: '/miniProgram/getScheme',
    method: 'GET',
    params
  })
}

